import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, Button, CircularProgress, Alert, Grid, FormControl, InputLabel, InputAdornment, Box, FormHelperText } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { fetchStateRegulations } from '../../api/applications';
import { fetchProgramsForSchool, calculateExampleTerms } from '../../api/programs';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const PaymentEstimator = ({ activeSchool }) => {
  const [programs, setPrograms] = useState([]);
  const [liveStates, setLiveStates] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [tuitionRequest, setTuitionRequest] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [disbursementDate, setDisbursementDate] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formTouched, setFormTouched] = useState(false);
  const [estimationResult, setEstimationResult] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [programsData, statesData] = await Promise.all([
          fetchProgramsForSchool(activeSchool.id),
          fetchStateRegulations()
        ]);
        setPrograms(programsData.programs);
        setLiveStates(Object.entries(statesData).filter(([_, data]) => data.is_live));

        if (programsData.programs.length > 0) {
          const firstProgram = programsData.programs[0];
          setSelectedProgram(firstProgram.id);
          setTuitionRequest(firstProgram.tuition.toFixed(2));
          const downPayment = (!firstProgram.required_down_payment ? 0 : parseFloat(firstProgram.required_down_payment))
          setDownPayment((downPayment).toFixed(2));
        }
      } catch (error) {
        logErrorToConsole("Error fetching initial data", error);
        logErrorToSentryWithContext(error, { schoolId: activeSchool.id });
        setError('There was an error loading active programs and states. Try refreshing the page?');
      }
    };

    fetchInitialData();
  }, [activeSchool.id]);

  const handleProgramChange = (e) => {
    const programId = e.target.value;
    setSelectedProgram(programId);
    const selectedProgram = programs.find(program => program.id === programId);
    if (selectedProgram) {
      setTuitionRequest(selectedProgram.tuition.toFixed(2));
      const downPayment = (!selectedProgram.required_down_payment ? 0 : parseFloat(selectedProgram.required_down_payment))
      setDownPayment(downPayment);
    }
    setEstimationResult(null);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setEstimationResult(null);
    setFormTouched(true);
  };

  const handleTuitionChange = (e) => {
    setTuitionRequest(e.target.value);
    setEstimationResult(null);
  };

  const handleDownPaymentChange = (e) => {
    setDownPayment(e.target.value);
    setEstimationResult(null);
  };

  const handleDateChange = (newValue) => {
    setDisbursementDate(newValue);
    setEstimationResult(null);
  };

  const handleEstimate = async () => {
    setIsLoading(true);
    setError('');
    setEstimationResult(null);

    try {
      const result = await calculateExampleTerms(
        activeSchool.id,
        selectedProgram,
        tuitionRequest,
        downPayment,
        selectedState,
        disbursementDate.format('YYYY-MM-DD')
      );
      setEstimationResult(result);
    } catch (error) {
      logErrorToConsole("Error calculating program terms", error);
      setError(error.response?.data?.error || 'An error occurred while estimating payments');
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = selectedProgram && selectedState && tuitionRequest && downPayment;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="flex flex-col w-full overflow-y-auto h-full">
        <div className="page-title">Payment Estimator</div>
        <hr className='my-2 line-divider'/>
        <div className="flex h-full mt-4">
          <div className="w-2/5 pr-6 flex flex-col">
            <p className="text-sm lg:text-base mb-4">
              Use this tool to estimate loan terms before students apply.
            </p>
            <div className="space-y-6">
              <FormControl fullWidth size="small" className="mb-4">
                <InputLabel id="program-select-label">Program</InputLabel>
                <Select
                  labelId="program-select-label"
                  value={selectedProgram}
                  onChange={handleProgramChange}
                  label="Program"
                >
                  {programs.map((program) => (
                    <MenuItem key={program.id} value={program.id}>{program.program_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Tuition Request"
                type="number"
                value={tuitionRequest}
                onChange={handleTuitionChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
                size="small"
                className="mb-4"
              />

              <TextField
                label="Down Payment"
                type="number"
                value={downPayment}
                onChange={handleDownPaymentChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
                size="small"
                className="mb-4"
              />

              <DatePicker
                label="Loan Start Date"
                value={disbursementDate}
                onChange={handleDateChange}
                slotProps={{ textField: { fullWidth: true, size: "small" } }}
                className="mb-4"
              />

              <FormControl fullWidth error={!formTouched} size="small" className="mb-4">
                <InputLabel id="state-select-label">Student's State of Residence</InputLabel>
                <Select
                  labelId="state-select-label"
                  value={selectedState}
                  onChange={handleInputChange(setSelectedState)}
                  label="Student's State of Residence"
                >
                  {liveStates.map(([state, _]) => (
                    <MenuItem key={state} value={state}>{state}</MenuItem>
                  ))}
                </Select>
                {!formTouched && (
                  <FormHelperText>Please select the student's state of residence.</FormHelperText>
                )}
              </FormControl>

              {error && <Alert severity="error" className="mb-4">{error}</Alert>}

              <Button
                variant="contained"
                color="primary"
                onClick={handleEstimate}
                disabled={!isFormValid || isLoading}
                className="mt-auto"
              >
                {isLoading ? <CircularProgress size={16} style={{ color: 'white' }} /> : 'Calculate'}
              </Button>
            </div>
          </div>
          
          <div className="w-3/5 pl-6 flex flex-col">
            {estimationResult ? (
              <>
                <Box className="bg-gray-100 p-6 rounded-lg">
                  <h2 className="text-base lg:text-xl font-semibold mb-4">Estimated Terms</h2>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <p className="text-sm lg:text-base font-semibold">Tuition Financed:</p>
                      <p>{formatCurrency(estimationResult.tuition_financed)}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p className="text-sm lg:text-base font-semibold">Final Loan Amount:</p>
                      <p>{formatCurrency(estimationResult.borrowing_amount)}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p className="text-sm lg:text-base font-semibold">Fortify Platform Fee:</p>
                      <p>{formatCurrency(estimationResult.fortify_platform_fee)}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p className="text-sm lg:text-base font-semibold">Monthly Payment:</p>
                      <p>{formatCurrency(estimationResult.monthly_payment)}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p className="text-sm lg:text-base font-semibold">Loan Term:</p>
                      <p>{estimationResult.loan_term} months</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p className="text-sm lg:text-base font-semibold">First Payment Due Date:</p>
                      <p>{formatDate(new Date(estimationResult.first_payment_due_date))}</p>
                    </Grid>
                  </Grid>
                </Box>
                <div className="mt-6">
                  <p className="text-sm lg:text-base text-gray-600">
                    Please note: although we like to think this calculator is pretty accurate, these terms are just estimates. Final loan terms could change; for example, if a student signs their loan later than expected, or if they live in a different state.<br/><br/>If you discuss these terms with your students, please mention that <b>students are not guaranteed to receive these terms</b>. They will need to submit an application to see if they're approved and to see what their actual terms are.
                  </p>
                </div>
              </>
            ) : (
              <div className="flex-grow flex items-center justify-center">
                <p align="center" className='text-legal-gray'>
                  Click 'Calculate' to see the estimated loan terms.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default PaymentEstimator;