import { initializeOrUpdateIntercomOnProd } from './utils/intercom';
import { isScreenSmall } from './utils/screenSize';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

import './styles/main.css';
import 'tailwindcss/tailwind.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';


import Login from './components/Login';
import Portal from './components/Portal';
import RequestPasswordReset from './components/RequestPasswordReset';
import ResetPassword from './components/ResetPassword';
import SetupAccount from './components/SetupAccount';
import AccessOnDesktop from './components/AccessOnDesktop';
import ErrorBoundary from './components/ErrorBoundary';
import { enableSentryReplayIntegrationIfNotEnabled } from '.';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00684a',
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '15px',
          borderRadius: '8px',
          height: '44px',
        },
      },
      variants: [
        {
          props: { variant: 'contained', customPadding: true },
          style: {
            padding: '8px 32px',
          },
        },
      ],
    },
  },
});

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    initializeOrUpdateIntercomOnProd();

    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    
    if (accessToken && refreshToken) {
      enableSentryReplayIntegrationIfNotEnabled();
    }

    if (isScreenSmall()) {
      navigate('/access-on-desktop');
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Portal />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-new-password" element={<SetupAccount/>} />
          <Route path="/setup-account" element={<SetupAccount/>} />
          <Route path="/access-on-desktop" element={<AccessOnDesktop />} />
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;