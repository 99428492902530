import React from 'react';

const Contact = () => {
  return (
    <div className="flex flex-col">
      <div className="page-title">Contact us</div>
      <hr className='mt-2 line-divider'/>
      <div className="mt-8">
        <p className="mb-6">Have a question? We're here to help. Please note our business hours are Monday to Friday, from 9am-5pm.<br/><br/>To get in touch, you can:</p>
        <div className="space-y-8">
          <div>
            <h2 className="text-xl font-semibold mb-2">Chat with us live</h2>
            <p>Just click the green chat icon in the bottom right of your screen.</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-2">Email us</h2>
            <p>Email us at <a href="mailto:help@fortifyedu.com" className="text-blue-600 hover:underline">help@fortifyedu.com</a>.</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-2">Call us</h2>
            <p>Call us at <b>(214) 644-6444</b> for student issues and general questions.</p>
            <br/>
            <p>If you see a software error or a bug in your portal, you can call our engineering team directly at <b>(971) 330-5506</b>.</p>
            <p>We promise the engineers are friendly :)</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-2">Read through our help center</h2>
            <p>If you're just looking for more information, check out our <a href="https://help.fortifyedu.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">help center</a>.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;