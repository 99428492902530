import React, { useState, useEffect } from 'react';
import ApplicationTable from './ApplicationTable';
import LoanApplicationData from '../../../models/LoanApplicationData';
import { fetchAllLoanApplicationsForSchool } from '../../../api/applications';
import { CircularProgress } from '@mui/material';
import ExportToCSV from '../../shared/ExportToCSV';
import PillButton from '../../shared/PillButton';

import sortLoanApplications from './sortLoanApplications';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const NewApplications = ({ activeSchool, isApplicationFrameOpen, onSelectApplication, refreshDataTrigger }) => {
  const [applicationsData, setApplicationsData] = useState(null);
  const [applicationsDataIsLoading, setApplicationsDataIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('incomplete');
  const [inactiveSection, setInactiveSection] = useState('ineligible');

  useEffect(() => {
    const fetchApplicationsData = async () => {
      if (activeSchool && activeSchool.id) {
        setApplicationsDataIsLoading(true);
        try {
          const data = await fetchAllLoanApplicationsForSchool(activeSchool.id);
          setApplicationsData(data);
        } catch (error) {
          logErrorToConsole("Error fetching applications data", error);
          logErrorToSentryWithContext(error, {
            schoolId: activeSchool.id,
          });
        } finally {
          setApplicationsDataIsLoading(false);
        }
      }
    };

    fetchApplicationsData();
  }, [activeSchool, refreshDataTrigger]);

  if (applicationsDataIsLoading || applicationsData?.matches === undefined) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  const loanApplications = applicationsData.matches.map(result => new LoanApplicationData(result));
  const sortedApplications = sortLoanApplications(loanApplications);

  const activeSections = [
    { key: 'incomplete', title: 'Incomplete', data: sortedApplications.pendingApplications },
    { key: 'schoolCertification', title: 'School Certification', data: sortedApplications.pendingSchoolCertificationApplications },
    { key: 'idVerification', title: 'ID Verification', data: sortedApplications.pendingIDVerificationApplications },
    { key: 'downPayment', title: 'Down Payment', data: sortedApplications.pendingDownPaymentApplications },
    { key: 'signature', title: 'Signature', data: sortedApplications.pendingSignatureApplications },
    { key: 'fundedLoans', title: 'Funded Loans', data: sortedApplications.fundedApplications },
  ];

  const inactiveSections = [
    { key: 'ineligible', title: 'Ineligible Apps', data: sortedApplications.ineligibleApplications },
    { key: 'archived', title: 'Archived Apps', data: sortedApplications.archivedApplications },
    { key: 'rejected', title: 'Rejected Apps', data: sortedApplications.rejectedApplications },
    { key: 'cancelled', title: 'Cancelled Loans', data: sortedApplications.cancelledApplications },
  ];

  const prepareCSVData = (data) => {
    const headers = ["Student Name", "Program", "Application Created Date", "Program Start Date"];
    const csvData = data.map(app => [
      `${app.student.account.firstName} ${app.student.account.lastName}`,
      app.program.programName,
      app.createdAt ? new Date(app.createdAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/Chicago' }).split(',')[0] : "",
      app.startDate ? new Date(app.startDate).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }).split(',')[0] : ""
    ]);
    return [headers, ...csvData];
  };

  const exportToCSV = (data, sectionTitle) => {
    const csvData = prepareCSVData(data);
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const currentDate = new Date().toISOString().split('T')[0];
      const filename = `Fortify ${sectionTitle} ${currentDate}.csv`;
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className='applications-container'>
      <div className='applications-section active-section'>
        <h2 className='section-header'>Active loans & applications</h2>
        <div className='control-row'>
          <div className='pill-buttons-container'>
            {activeSections.map(section => (
              <PillButton
                key={section.key}
                text={section.title}
                isActive={activeSection === section.key}
                onClick={() => setActiveSection(section.key)}
                count={section.data.length}
                category={section.key}
              />
            ))}
          </div>
          {activeSections.find(s => s.key === activeSection).data.length > 0 && (
            <ExportToCSV
              exportToCSV={() => exportToCSV(activeSections.find(s => s.key === activeSection).data, activeSections.find(s => s.key === activeSection).title)}
            />
          )}
        </div>
        <div className='table-container'>
          <ApplicationTable
            data={activeSections.find(s => s.key === activeSection).data}
            isApplicationFrameOpen={isApplicationFrameOpen}
            onSelectApplication={onSelectApplication}
          />
        </div>
      </div>

      <div className='applications-section inactive-section'>
        <h2 className='section-header'>Inactive applications</h2>
        <div className='control-row'>
          <div className='pill-buttons-container'>
            {inactiveSections.map(section => (
              <PillButton
                key={section.key}
                text={section.title}
                isActive={inactiveSection === section.key}
                onClick={() => setInactiveSection(section.key)}
                count={section.data.length}
                category={section.key}
              />
            ))}
          </div>
          {inactiveSections.find(s => s.key === inactiveSection).data.length > 0 && (
            <ExportToCSV
              exportToCSV={() => exportToCSV(inactiveSections.find(s => s.key === inactiveSection).data, inactiveSections.find(s => s.key === inactiveSection).title)}
            />
          )}
        </div>
        <div className='table-container'>
          <ApplicationTable
            data={inactiveSections.find(s => s.key === inactiveSection).data}
            isApplicationFrameOpen={isApplicationFrameOpen}
            onSelectApplication={onSelectApplication}
          />
        </div>
      </div>
    </div>
  );
};

export default NewApplications;
