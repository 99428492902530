import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { fetchStateRegulations } from '../../api/applications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const formatCurrency = (amount) => {
  if (amount == null) return "";
  return `$${parseFloat(amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const LiveStates = () => {
  const [stateRegulations, setStateRegulations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStateRegulations();
        const sortedData = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
        setStateRegulations(sortedData);
      } catch (error) {
        logErrorToConsole("Error fetching state regulations", error);
        logErrorToSentryWithContext(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading || !stateRegulations) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="page-title">Live States</div>
      <hr className='my-2 line-divider'/>
      <div className="mt-6 overflow-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="sticky top-0 bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-left">State</th>
              <th className="py-2 px-4 border-b text-left">Live</th>
              <th className="py-2 px-4 border-b text-left">Minimum Loan Amount</th>
              <th className="py-2 px-4 border-b text-left">Maximum Loan Amount</th>
            </tr>
          </thead>
          <tbody>
            {stateRegulations.map(([state, data]) => (
              <tr key={state} className="border-b font-medium" style={{ 
                backgroundColor: data.is_live ? 'rgba(0, 104, 74, 0.20)' : 'rgba(218, 66, 68, 0.20)',
                color: data.is_live ? '#00684a' : '#DA4244',
              }}>
                <td className="py-2 px-4">
                  {state}
                </td>
                <td className="py-2 px-4">{data.is_live ? 'Yes' : 'No'}</td>
                <td className="py-2 px-4">{data.minimum_tuition_financed ? formatCurrency(data.minimum_tuition_financed) : ''}</td>
                <td className="py-2 px-4">{data.maximum_loan_amount ? formatCurrency(data.maximum_loan_amount) : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveStates;
