import React from 'react';

const ApplicationTable = ({ data, isApplicationFrameOpen, onSelectApplication }) => {
  const tableContent = (
    <div className="application-table-wrapper">
      <table className="application-table">
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Student</th>
            <th style={{ width: '25%' }}>Program</th>
            <th style={{ width: '20%' }}>App Started On</th>
            <th style={{ width: '20%' }}>Start Date</th>
            <th style={{ width: '10%' }}></th>
          </tr>
        </thead>
        <tbody>
          {data.map((application, index) => (
            <tr
              key={index}
              onClick={(event) => {
                if (!isApplicationFrameOpen) {
                  event.preventDefault();
                  event.stopPropagation();
                  onSelectApplication(application);
                }
              }}
              className={!isApplicationFrameOpen ? "clickable" : ""}
            >
              <td>{`${application.student.account.firstName} ${application.student.account.lastName}`}</td>
              <td>{application.program.programName}</td>
              <td>{application.createdAt ? new Date(application.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/Chicago' }) : ""}</td>
              <td>{application.startDate ? new Date(application.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }) : ""}</td>
              <td className="justify-right expand-icon-cell">
                <img src="/expandApplicationIcon.svg" alt="Expand" className="expand-icon" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const getContainerClass = () => {
    if (data.length === 0) return 'empty';
    if (data.length < 5) return 'few-rows';
    return 'many-rows';
  };

  return (
    <div className={`application-table-container ${getContainerClass()}`}>
      {data.length === 0 ? (
        <div className="application-table-empty">
          No applications found
        </div>
      ) : tableContent}
    </div>
  );
};

export default ApplicationTable;
