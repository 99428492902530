import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDisbursementsForSchool } from '../../api/applications';
import { DisbursementData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV } from '../../utils/formatters';
import ExportToCSV from '../shared/ExportToCSV';

const Disbursements = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [disbursementsData, setDisbursementsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!activeSchool.is_admin) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool, navigate]);

  const fetchData = async () => {
    try {
      const data = await fetchDisbursementsForSchool(activeSchool.id);
      const formattedData = data.map(item => new DisbursementData(item));
      const filteredData = formattedData.filter(item => item.amountDisbursed !== 0);
      const sortedData = filteredData.sort((a, b) => b.disbursementDate - a.disbursementDate);
      setDisbursementsData(sortedData);
    } catch (error) {
      console.error("Error fetching disbursements data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReportDownload = (reportUrl) => {
    window.open(reportUrl, '_blank');
  };

  const exportToCSV = () => {
    const headers = ["Disbursement Date", "Amount Disbursed", "Full Disbursement Report"];
    const csvData = disbursementsData.map(item => [
      formatDateForCSV(item.disbursementDate),
      formatCurrencyForCSV(item.amountDisbursed),
      item.report
    ]);
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `Fortify Disbursements.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (disbursementsData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Disbursements</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              You don't have any disbursements yet.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Disbursements</div>
        <ExportToCSV exportToCSV={exportToCSV} />
      </div>
      <hr className='my-2 line-divider'/>
      <div className="flex-grow overflow-hidden">
        <div className="overflow-x-auto overflow-y-auto h-full">
          <table className="w-full table-auto border border-gray-300">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Disbursement Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Amount Disbursed</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Reports</th>
              </tr>
            </thead>
            <tbody>
              {disbursementsData.map((item, index) => (
                <tr key={index} className="border-b h-12">
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.disbursementDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.amountDisbursed)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <button
                      onClick={() => handleReportDownload(item.report)}
                      className="flex items-center text-black no-underline hover:underline"
                    >
                      Download report
                      <img src="/download_icon.svg" alt="Download" className="w-5 h-5 ml-2" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Disbursements;