import React, { useState } from 'react';
import { Dialog, Button, TextField, Alert, CircularProgress } from '@mui/material';
import { certifyLoanApplication, getMonthlyPayment } from '../../../api/applications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';
import dayjs from 'dayjs';

const CertificationModal = ({ open, onClose, applicationData, onActionSuccess }) => {
  const [isEditingTuitionRequested, setIsEditingTuitionRequested] = useState(false);
  const [tuitionRequested, setTuitionRequested] = useState(parseFloat(applicationData.tuitionRequest));
  const [lastTuitionRequestForPaymentCalc, setLastTuitionRequestForPaymentCalc] = useState(parseFloat(applicationData.tuitionRequest));

  const [isEditingDownPayment, setIsEditingDownPayment] = useState(false);
  const [downPayment, setDownPayment] = useState(!applicationData.loan.requiredDownPayment ? 0 : parseFloat(applicationData.loan.requiredDownPayment));
  const [lastDownPaymentForPaymentCalc, setLastDownPaymentForPaymentCalc] = useState(parseFloat(!applicationData.loan.requiredDownPayment ? 0 : parseFloat(applicationData.loan.requiredDownPayment)));

  const [monthlyPayment, setMonthlyPayment] = useState(!applicationData.loan.monthlyPayment ? null : parseFloat(applicationData.loan.monthlyPayment));
  const [loanTerm, setLoanTerm] = useState(applicationData.loan.loanTerm);

  const [isPaymentTermsLoading, setIsPaymentTermsLoading] = useState(false);
  const [paymentTermsError, setPaymentTermsError] = useState(null);

  const [startDate, setStartDate] = useState(applicationData.startDate);
  const [gradDate, setGradDate] = useState(applicationData.gradDate);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchMonthlyPayment = async () => {
    setIsEditingTuitionRequested(false);
    setIsEditingDownPayment(false);

    setIsPaymentTermsLoading(true);
    setPaymentTermsError(null);

    setLastTuitionRequestForPaymentCalc(tuitionRequested);
    setLastDownPaymentForPaymentCalc(downPayment);
    try {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD')
      const response = await getMonthlyPayment(applicationData.schoolId, applicationData.id, tuitionRequested, downPayment, formattedStartDate);
      setMonthlyPayment(parseFloat(response.monthly_payment));
      setLoanTerm(response.loan_term);
    } catch (error) {
      logErrorToConsole("Failed to fetch monthly payment", error);
      setPaymentTermsError(error.response.data?.error ? `Error: ${error.response.data?.error}` : 'Please choose a valid tuition request & down payment to see the monthly payment.')

      if (
        error.response?.status === 400 &&
        !!error.response?.data?.error
      ) {
        // don't raise an alert
      } else {
        logErrorToSentryWithContext(error, {
          schoolId: applicationData.schoolId,
          applicationId: applicationData.id,
          tuitionRequested,
          downPayment,
          startDate,
        });
      }
    } finally {
      setIsPaymentTermsLoading(false);
    }
  };

  const handleToggleEditTuitionRequested = () => {
    if (isEditingTuitionRequested) {
      fetchMonthlyPayment();
    } else {
      setIsEditingTuitionRequested(true);
    }
  };

  const handleToggleEditDownPayment = () => {
    if (isEditingDownPayment) {
      fetchMonthlyPayment();
    } else {
      setIsEditingDownPayment(true);
    }
  };

  const handleTuitionChange = async (e) => {
    setError('');
    const value = parseFloat(e.target.value);
    setTuitionRequested(isNaN(value) ? 0 : value);
  };

  const handleDownPaymentChange = async (e) => {
    setError('');
    const value = parseFloat(e.target.value);
    setDownPayment(isNaN(value) ? 0 : value);
  };

  // TODO: i would love to trigger a fetchMonthlyPayment here, but can't figure it out.
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const formatCurrency = (amount) => {
    if (!amount == null) return "";
    return `${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  const handleCertify = async () => {
    setIsLoading(true);
    setError('');
    try {
      await certifyLoanApplication(applicationData.schoolId, applicationData.id, {
        decision: "approved",
        tuition_request: tuitionRequested,
        down_payment: downPayment,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        grad_date: dayjs(gradDate).format('YYYY-MM-DD'),
      });
      onClose();
      onActionSuccess();
    } catch (error) {
      logErrorToConsole("Error certifying loan application", error);
      setError(error.response.data?.error || 'Failed to certify loan application');

      if (
        error.response?.status === 400 &&
        !!error.response?.data?.error
      ) {
        // don't raise an alert
      } else {
        logErrorToSentryWithContext(error, {
          schoolId: applicationData.schoolId,
          applicationId: applicationData.id,
          tuitionRequested,
          downPayment,
          startDate,
          gradDate
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const tuitionFinanced = tuitionRequested - downPayment;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '26px' } }}>
      <div className='px-12 py-12'>
        <div>
          <h1 className="text-xl mb-8"><b>Certify {applicationData.student.account.firstName} {applicationData.student.account.lastName}'s application</b></h1>

          <div className="mb-8">
            <h1 className="text-lg mb-2 font-semibold">Program</h1>
            <p className="mb-2 text-[15px] font-semibold">{applicationData.program.programName}</p>
            <p className="text-gray-700 text-[13px]">Need to adjust {applicationData.student.account.firstName}'s program? Please <a href="mailto:help@fortifyedu.com" className="underline">contact us</a>.</p>
          </div>

          <div className="mb-8">
            <h1 className="text-lg mb-2 font-semibold">Loan Terms</h1>
            <div className="flex justify-between items-center mb-4">
              <p className="text-[15px]">Tuition Requested</p>

              <div className="flex items-center">
                <img
                  className="w-7 h-5 mr-2"
                  src="/certificationModal/tuition_dollar.svg"
                  alt="$"
                />
                {isEditingTuitionRequested ? (
                  <TextField
                    className="max-w-[100px]"
                    value={tuitionRequested}
                    onChange={handleTuitionChange}
                    size="small"
                    inputProps={{ style: { textAlign: 'right' } }}
                    InputProps={{ style: { borderRadius: '8px' } }}
                  />
                ) : (
                  <div className="min-w-[75px] text-right">{formatCurrency(tuitionRequested)}</div>
                )}
                <div onClick={handleToggleEditTuitionRequested}>
                  <img
                    className="ml-4 w-5 h-5"
                    src={isEditingTuitionRequested ? "/certificationModal/save.svg" : "/certificationModal/edit.svg"}
                    alt="Edit Icon"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mb-4">
              <p className="text-[15px]">Down Payment (through Fortify)</p>
              <div className="flex items-center">
                <img
                  className="w-7 h-5 mr-2"
                  src="/certificationModal/down_payment_dollar.svg"
                  alt="-$"
                />
                {isEditingDownPayment ? (
                  <TextField
                    className="max-w-[100px]"
                    value={downPayment}
                    onChange={handleDownPaymentChange}
                    size="small"
                    inputProps={{ style: { textAlign: 'right' } }}
                    InputProps={{ style: { borderRadius: '8px' } }}
                  />
                ) : (
                  <div className="min-w-[75px] text-right">{formatCurrency(downPayment)}</div>
                )}
                <div onClick={handleToggleEditDownPayment}>
                  <img
                    className="ml-4 w-5 h-5"
                    src={isEditingDownPayment ? "/certificationModal/save.svg" : "/certificationModal/edit.svg"}
                    alt="Edit Icon"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-2 mb-4 line-divider" />

            <div className="flex justify-between items-center">
              <p className="text-[15px] font-semibold">Tuition Financed</p>

              <div className="flex items-center space-x-4">
                <p className="text-base font-semibold">{formatCurrency(tuitionFinanced)}</p>
                <div className="w-5 h-5"></div>
              </div>
            </div>

          </div>

          <div className="mb-8">
            <h1 className="text-lg mb-6 font-semibold">Enrollment Dates</h1>
            <div className="flex justify-between space-x-8">
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="medium"
                InputProps={{ style: { borderRadius: '8px', height: '48px' } }}
              />
              <TextField
                label="Grad Date"
                type="date"
                value={gradDate}
                onChange={(e) => setGradDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="medium"
                InputProps={{ style: { borderRadius: '8px', height: '48px' } }}
              />
            </div>
          </div>
          
          <div className="mb-8 py-6 px-6 mx-auto border-[1px] border-fortify-green/60 rounded-lg bg-fortify-green bg-opacity-5">
            {isPaymentTermsLoading ? (
              <div className="flex justify-center items-center py-4 px-4 h-full">
                <CircularProgress size={15}/>
              </div>
            ) : (
              <>
                {paymentTermsError ? (
                  <p className="text-fortify-green font-medium">{paymentTermsError}</p>
                ) : (
                  <p className="text-fortify-green font-medium">
                    Based on a ${formatCurrency(lastTuitionRequestForPaymentCalc)} tuition request and a ${formatCurrency(lastDownPaymentForPaymentCalc)} down payment, {applicationData.student.account.firstName}'s payments will be <strong>${formatCurrency(monthlyPayment)}</strong>/month for <strong>{loanTerm} months</strong>.
                  </p>
                )}
              </>
            )}
          </div>

          {error && <Alert severity="error" style={{ marginBottom: '2rem' }}>{error}</Alert>}

          {(isEditingDownPayment || isEditingTuitionRequested) && <p className='text-legal-gray text-sm mb-6 font-light'>Please save your changes above before you certify this application.</p>}
          <div className="flex justify-center">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div className="flex justify-between space-x-4 w-full">
                <Button 
                  className="flex-grow" 
                  variant="outlined" 
                  color="primary" 
                  onClick={onClose} 
                  sx={{ 
                    color: 'fortify-green', 
                    borderColor: 'fortify-green', 
                    textTransform: 'none', 
                    height: '48px', 
                    fontSize: '1rem', 
                    fontWeight: 'medium',
                    borderRadius: '10px'
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  className="flex-grow"
                  variant="contained"
                  color="primary"
                  sx={{ 
                    backgroundColor: 'fortify-green', 
                    textTransform: 'none', 
                    height: '48px', 
                    fontSize: '1rem', 
                    fontWeight: 'medium',
                    borderRadius: '10px'
                  }}
                  onClick={handleCertify}
                  disabled={paymentTermsError || isEditingTuitionRequested || isEditingDownPayment}
                >
                  Certify
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CertificationModal;