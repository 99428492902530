import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const UserSection = ({ user, onLogout, handleSelectTab, selectedTab }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const modalRef = useRef(null);
  const dotsRef = useRef(null);

  const truncateEmail = (email) => {
    return email.length > 22 ? email.substring(0, 22) + '...' : email;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        dotsRef.current &&
        !dotsRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDotsClick = (event) => {
    event.stopPropagation();
    setIsModalOpen((prevState) => !prevState);
  };

  const getIcon = (item) => {
    const isActive = selectedTab === item.toLowerCase();
    const isHovered = hoveredItem === item;
    if (isActive) {
      return `./general/${item.toLowerCase()}_active.svg`;
    }
    return isHovered
      ? `./general/${item.toLowerCase()}_hover.svg`
      : `./general/${item.toLowerCase()}.svg`;
  };

  const renderMenuItem = (item, onClick) => {
    const isActive = selectedTab === item.toLowerCase();
    const isHovered = hoveredItem === item;

    return (
      <div
        className={`px-4 py-3 cursor-pointer flex items-center ${
          isActive ? 'sidebar-link-active' : 'sidebar-link-inactive'
        }`}
        onClick={() => {
          onClick();
          setIsModalOpen(false);
        }}
        onMouseEnter={() => setHoveredItem(item)}
        onMouseLeave={() => setHoveredItem(null)}
      >
        <img src={getIcon(item)} alt={item} className="w-5 h-5 mr-3" />
        <span className={`text-sm ${
          isActive ? 'font-semibold text-fortify-green' : isHovered ? 'text-sidebar-hover-green' : 'text-[#6a6a6a]'
        }`}>
          {item}
        </span>
      </div>
    );
  };

  return (
    <div className="user-section relative">
      <div className="user-section-content">
        <img src="/person.svg" alt="Profile" className="user-profile-image" />
        <div className="user-info">
          <div className="user-name">{user.name}</div>
          <div className="user-email">{truncateEmail(user.email)}</div>
        </div>
        <img
          ref={dotsRef}
          src="./sidebarIcons/userSectionDots.svg"
          alt="Menu"
          className="user-section-dots cursor-pointer"
          onClick={handleDotsClick}
        />
      </div>
      {isModalOpen && (
        <div
          ref={modalRef}
          className="absolute bottom-full left-0 mb-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden"
        >
          {renderMenuItem('Settings', () => handleSelectTab('settings'))}
          {renderMenuItem('Contact', () => handleSelectTab('contact'))}
          {renderMenuItem('Logout', onLogout)}
        </div>
      )}
    </div>
  );
};

export default UserSection;